/**
 * 法币 - 冲提
 */
export enum TabType {
  // 充值
  charge = 'charge',
  // 提现
  withdraw = 'withdraw',
}

export enum PaymentManagement {
  // 当面现金交易
  FaceToFace = 'FaceToFace',
}

/** 法币收付款方式字典值 */
export enum PaymentDicCodeEnum {
  /** 支付方式字典 */
  paymentDicCode = 'pay_method_cd',
  c2cPaymentType_cd = 'c2c_payment_type_cd',
  c2cPaymentColor = 'c2c_payment_color',
}

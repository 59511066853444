import { useEffect, useState } from 'react'
import CustomModal from '@/features/agent/modal'
import { t } from '@lingui/macro'
import { FiatDepositLayout } from '@/features/assets/main/deposit/fiat-deposit'
import { AllChargeListResp, AllChargePayInfoResp, AllFiatCurrencyResp } from '@/typings/api/assets/fiat'
import { getChargeList } from '@/apis/assets/fiat'
import { CodeGetCodeDetailListData } from '@/typings/api/assets/fiat'

interface FiatDepositLayoutModalProps {
  /** 关闭弹窗回调 */
  setClose: () => void
  /** 法币 */
  fiatCode: string
  /** 打开弹窗 */
  isShowModal: boolean
  paymentDealType: CodeGetCodeDetailListData[]
}

function FiatDepositLayoutModal({ isShowModal, setClose, fiatCode, paymentDealType }: FiatDepositLayoutModalProps) {
  const [fiatPayInfo, setFiatPayInfo] = useState<AllChargePayInfoResp[]>([]) // 支付方式
  const [topUpGradList, setTopUpGradList] = useState<AllChargeListResp[]>([]) // 充值档次
  const [fiatCurrency, setFiatCurrency] = useState<AllFiatCurrencyResp>({} as AllFiatCurrencyResp)

  const getChargeListInfo = async fiatCode => {
    const res = await getChargeList({ fiatCode })
    if (res && res.data) {
      const { levels, payInfo, fiatCurrency } = res.data
      addChannelName(payInfo, paymentDealType)
      setTopUpGradList(levels || [])
      setFiatCurrency(fiatCurrency)
    }
  }

  const changeCharge = val => {
    getChargeListInfo(val)
  }

  useEffect(() => {
    isShowModal && getChargeListInfo(fiatCode)
  }, [isShowModal])

  const addChannelName = (payInfo, channelInfo) => {
    for (const info of payInfo) {
      const matchingChannel = channelInfo.find(item => info.groupCode === item.codeVal)
      if (matchingChannel) {
        info.channelName = matchingChannel.codeKey
      } else {
        info.channelName = null
      }
    }
    setFiatPayInfo(payInfo)
  }

  return (
    <div>
      <CustomModal style={{ width: 600 }} visible={isShowModal}>
        <FiatDepositLayout
          fiatPayInfo={fiatPayInfo}
          topUpGradList={topUpGradList}
          fiatCurrency={fiatCurrency}
          firstFiatCode={fiatCode}
          onclick={setClose}
          changeCharge={changeCharge}
        />
      </CustomModal>
    </div>
  )
}

export { FiatDepositLayoutModal }
